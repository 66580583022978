import axios from 'axios';
import {
  Answer,
  ContainerKnownGaps,
  ContainerQuestion,
  Document,
  IndirectCostBasis,
  KnownGap,
  Question,
  QuestionAnswerResponse,
  DocumentReportExport,
} from '@/features/documents/types';

export abstract class DocumentService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getDocumentsByAwardProgramId(
    awardProgramId: string
  ): Promise<Document[]> {
    const response = await axios.get(this.basePath, {
      params: { awardProgramId },
    });

    return response.data;
  }

  async getOneById(id: string): Promise<Document> {
    const response = await axios.get(`${this.basePath}/${id}`);

    return response.data;
  }

  async start(documentId: string): Promise<Document> {
    const response = await axios.post(`${this.basePath}/${documentId}/start`);

    return response.data;
  }

  async submit(
    documentId: string,
    submissionReasons?: string[],
    submissionNotes?: string
  ): Promise<Document> {
    const response = await axios.post(`${this.basePath}/${documentId}/submit`, {
      submissionReasons,
      submissionNotes,
    });

    return response.data;
  }

  async startReview(documentId: string): Promise<Document> {
    const response = await axios.post(`${this.basePath}/${documentId}/review`);

    return response.data;
  }

  async return(documentId: string): Promise<Document> {
    const response = await axios.post(`${this.basePath}/${documentId}/return`);

    return response.data;
  }

  async approve(documentId: string): Promise<Document> {
    const response = await axios.post(`${this.basePath}/${documentId}/approve`);

    return response.data;
  }

  async archive(documentId: string): Promise<Document> {
    const response = await axios.post(`${this.basePath}/${documentId}/archive`);

    return response.data;
  }

  async getKnownGaps(id: string): Promise<ContainerKnownGaps[]> {
    const response = await axios.get(`${this.basePath}/${id}/known_gaps`);

    return response.data;
  }

  async addKnownGap(
    id: string,
    containerId: string | number,
    knownGap?: KnownGap
  ): Promise<KnownGap> {
    const response = await axios.post(`${this.basePath}/${id}/known_gaps`, {
      templateContainerId: containerId,
      explanation: knownGap ? knownGap.explanation : '',
    });

    return response.data;
  }

  async saveKnownGap(id: string, knownGap: KnownGap): Promise<KnownGap> {
    const response = await axios.put(
      `${this.basePath}/${id}/known_gaps/${knownGap.id}`,
      {
        explanation: knownGap.explanation,
      }
    );

    return response.data;
  }

  async deleteKnownGap(id: string, knownGapId: string): Promise<void> {
    const response = await axios.delete(
      `${this.basePath}/${id}/known_gaps/${knownGapId}`
    );

    return response.data;
  }

  async addKnownGapComment(
    documentId: string,
    knownGapId: string | undefined,
    containerId: string | number,
    content = ''
  ): Promise<KnownGap> {
    let finalKnownGapId = knownGapId;
    if (!finalKnownGapId) {
      const knownGap = await this.addKnownGap(documentId, containerId);

      finalKnownGapId = knownGap.id;
    }

    const response = await axios.post(
      `${this.basePath}/${documentId}/known_gaps/${finalKnownGapId}/comments`,
      { comment: { content } }
    );

    return response.data;
  }

  async addDocumentComment(
    documentId: string,
    content = ''
  ): Promise<Document> {
    const response = await axios.post(
      `${this.basePath}/${documentId}/comment/`,
      { comment: { content } }
    );

    return response.data;
  }

  async getQuestions(id: string): Promise<ContainerQuestion[]> {
    const response = await axios.get(`${this.basePath}/${id}/questions`);

    return response.data;
  }

  async answerQuestion(
    documentId: string,
    attachedContainerId: string,
    questionId: string,
    questionContainerId: string,
    answers: Answer[]
  ): Promise<QuestionAnswerResponse> {
    const response = await axios.put(
      `${this.basePath}/${documentId}/questions`,
      {
        question: {
          questionId,
          attachedContainerId,
          questionContainerId,
          answers,
        },
      }
    );

    return response.data;
  }

  async addQuestionComment(
    documentId: string,
    questionId: string,
    attachedContainerId: string,
    questionContainerId: string,
    content = ''
  ): Promise<Question> {
    const response = await axios.post(
      `${this.basePath}/${documentId}/question/${questionId}/comment`,
      {
        question: {
          attachedContainerId,
          questionContainerId,
          comment: { content },
        },
      }
    );

    return response.data;
  }

  async updateIndirectCostBasis(
    documentId: string,
    indirectCostBasis: IndirectCostBasis
  ): Promise<void> {
    const response = await axios.post(
      `${this.basePath}/${documentId}/indirect_cost_basis`,
      { indirectCostBasis }
    );

    return response.data;
  }

  async updateIndirectCostRate(
    documentId: string,
    indirectCostRate: number | null
  ): Promise<void> {
    const response = await axios.post(
      `${this.basePath}/${documentId}/indirect_cost_rate`,
      { indirectCostRate }
    );

    return response.data;
  }

  async getReportExport(
    documentId: string,
    name: string
  ): Promise<DocumentReportExport> {
    const response = await axios.get(
      `${this.basePath}/${documentId}/report_export`,
      { params: { report: { name } } }
    );

    return response.data;
  }
}
