import { useEffect, useState } from 'react';

interface Props {
  placeholder: string;
  isValid?: boolean;
  validateOnChange?: boolean;
  defaultValue?: string | number;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: string) => void;
  onEnterPressed?: (value: string) => void;
  name?: string;
  headerName?: string;
  subHeaderName?: string;
  type?: string;
  pattern?: string;
  disabled?: boolean;
  min?: string;
  required?: boolean;
  className?: string;
  autoComplete?: string;
  headerClassName?: string;
  icon?: string;
  iconPosition?: 'left' | 'right';
  step?: string;
  preventSubmitOnEnter?: boolean;
}

export default function TextInput({
  placeholder,
  isValid: isValidDefault = true,
  validateOnChange,
  defaultValue,
  onChange,
  onBlur,
  onEnterPressed,
  name,
  headerName,
  subHeaderName,
  type = 'text',
  pattern,
  disabled,
  min,
  required,
  className,
  value,
  autoComplete,
  headerClassName = 'text-grey-black mt-6',
  icon,
  iconPosition = 'right',
  step,
  preventSubmitOnEnter = false,
}: Props) {
  const [isValid, setIsValid] = useState(isValidDefault);

  useEffect(() => {
    setIsValid(isValidDefault);
  }, [isValidDefault]);

  function onInvalid() {
    setIsValid(false);
  }

  function onInputChange(value: React.ChangeEvent<HTMLInputElement>) {
    setIsValid(validateOnChange ? isValidDefault : true);

    if (onChange) {
      onChange(value);
    }
  }

  return (
    <div>
      {headerName && (
        <div className="mb-1">
          <p className={`text-base-sm font-semibold ${headerClassName}`}>
            {headerName}
          </p>

          {subHeaderName && (
            <p className={`text-description-sm text-grey-black`}>
              {subHeaderName}
            </p>
          )}
        </div>
      )}
      <label className={`${icon ? 'relative' : ''}`}>
        {icon && (
          <span
            className={`${icon} pointer-events-none absolute top-1/2 mt-0.5 h-8 w-8 -translate-y-1/2 transform ${
              iconPosition === 'left' ? 'left-2' : 'right-3'
            }`}
          />
        )}{' '}
        <input
          disabled={disabled}
          pattern={pattern}
          required={required}
          className={`organization-input ${className} ${
            (isValid ?? true) ? '' : 'invalid-input'
          } ${disabled ? '!bg-pale-grey !text-grey-700' : ''} ${icon ? (iconPosition === 'left' ? '!pl-8' : 'pr-8') : ''} `}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onInputChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e.target.value);
            }
          }}
          min={min}
          value={value}
          autoComplete={autoComplete}
          onInvalid={onInvalid}
          step={step}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (preventSubmitOnEnter) {
                e.preventDefault();
              }

              if (onEnterPressed) {
                onEnterPressed(e.currentTarget.value);
              }
            }
          }}
        />
      </label>
      {!isValid && name && <span className="error-message">{name}</span>}
    </div>
  );
}
