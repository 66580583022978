import { FilterProps } from 'react-table';

type ColumnFilterT<T extends object> = (props: FilterProps<T>) => JSX.Element;

function getDefaultColumnFilter<T extends object>(): ColumnFilterT<T> {
  return function DefaultColumnFilter({
    column: { filterValue, setFilter, Header },
  }) {
    return (
      <div className="relative">
        <span className="action-search-icon absolute left-2 top-2" />
        <input
          className="w-full rounded-lg bg-white py-2 pl-9 text-grey-30 placeholder:text-grey-30 focus:outline-deep-blue"
          type="text"
          title="Sort"
          value={filterValue || ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value.trim() === '') {
              setFilter(undefined);
            } else {
              setFilter(value);
            }
          }}
          placeholder={`${Header} `}
        />
      </div>
    );
  };
}

export function getDefaultColumn<T extends object>() {
  return {
    Filter: getDefaultColumnFilter<T>(),
    filter: 'text',
  };
}
