import StorageService from '@/services/StorageService';
import ToastService from '@/services/ToastService';
import { ReactNode, createContext, useContext } from 'react';
import LogService from './LogService';

interface CommonServices {
  storageService: StorageService;
  toastService: ToastService;
  logService: LogService;
}

const ServiceContext = createContext<CommonServices>({
  storageService: new StorageService(),
  toastService: new ToastService(),
  logService: new LogService(),
});

export const CommonServicesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const storageService = new StorageService();
  const toastService = new ToastService();
  const logService = new LogService();

  return (
    <ServiceContext.Provider
      value={{
        storageService,
        toastService,
        logService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export function useCommonServices(): CommonServices {
  return useContext(ServiceContext);
}
