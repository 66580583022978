import { ReactNode } from 'react';

interface Props {
  id?: string | number;
  name?: string | ReactNode;
  isDisabled?: boolean;
  defaultChecked?: boolean;
  onAnswer?: (input: boolean) => void;
  isChecked?: boolean;
  description?: string;
}

export default function Checkbox({
  isDisabled,
  defaultChecked,
  id,
  isChecked,
  onAnswer,
  name,
  description,
}: Props) {
  return (
    <div className="flex items-center">
      <div>
        <input
          disabled={isDisabled}
          defaultChecked={defaultChecked}
          checked={isChecked}
          type="checkbox"
          name="checkbox"
          id={`checkbox-${id}`}
          onChange={(event) => {
            event.stopPropagation();
            onAnswer?.(event.target.checked);
          }}
          className="custom-checkbox default-input-hidden mt-2 hidden"
        />
        <label
          htmlFor={`checkbox-${id}`}
          className={`custom-checkbox-label ${isDisabled && 'disabled'} ${
            isChecked || defaultChecked ? 'checked' : ''
          }`}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </div>
      <p
        className={`mt-0.5 !pb-0 ${
          name ? 'ml-[1.15rem] text-base-sm font-medium' : ''
        }`}
      >
        {name}
        {description && <>: {description}</>}
      </p>
    </div>
  );
}
