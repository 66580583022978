import {
  PropsWithChildren,
  useContext,
  useEffect,
  createContext,
  useRef,
  useCallback,
} from 'react';
import { Consumer, createConsumer } from '@rails/actioncable';
import { useCommonServices } from './CommonServicesProvider';

interface CableContextType {
  cable: Consumer;
  unsubscribeAll: () => void;
}

export const CableContext = createContext({} as CableContextType);

const CABLE_URL = import.meta.env.VITE_CABLE_URL;

const CableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { storageService, logService } = useCommonServices();
  const cableUrl = `${CABLE_URL}?token=${storageService.getToken()}`;
  const cableRef = useRef<Consumer>(createConsumer(cableUrl));

  const unsubscribeAll = useCallback(() => {
    if (!cableRef.current) return;

    cableRef.current.subscriptions.subscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }, [cableRef]);

  useEffect(() => {
    const consumer = cableRef.current;

    return () => {
      try {
        consumer?.disconnect();
      } catch (error: unknown) {
        logService.error('Error disconnecting from cable');
      }
    };
  });

  return (
    <CableContext.Provider value={{ cable: cableRef.current, unsubscribeAll }}>
      {children}
    </CableContext.Provider>
  );
};

interface CableSubscriptionCallbacks<T> {
  connected?: () => void;
  disconnected?: () => void;
  received: (data: T) => void;
}

export const useCableSubscription = <T,>(
  channel: string,
  params: { [key: string]: any },
  callbacks: CableSubscriptionCallbacks<T>
) => {
  const { cable } = useContext(CableContext);

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      { channel, ...params },
      callbacks
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [cable, channel, params, callbacks]);
};

export default CableProvider;
