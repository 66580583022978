import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem } from 'react-pro-sidebar';
import { AwardProgram } from '@/features/awards/types';
import { PlanType } from '@/features/iam/types';
import { useCommonServices } from '@/services/CommonServicesProvider';

const SidebarAccordion = ({
  menuType,
  menuNames,
  grants,
}: {
  menuType: 'documents' | 'templates';
  menuNames: string[];
  grants: AwardProgram[];
}) => {
  const { storageService } = useCommonServices();
  const location = useLocation();
  const roles = storageService.getUserRoles() ?? [];

  const [expandedPanels, setExpandedPanels] = useState<string[]>([]);
  const menuOptions: { [type: string]: string } = {
    cpg: 'Planning Guide',
    cpgs: 'Planning Guide',
    wp: 'Work Plan',
    wps: 'Work Plan',
    sp: 'Spend Plan',
    sps: 'Spend Plan',
  };

  useEffect(() => {
    const expandedMenus: string[] = [];
    menuNames.forEach((name) => {
      const isExpanded =
        (menuType === 'documents' && location.pathname.includes(`/${name}`)) ||
        (menuType === 'templates' &&
          location.pathname.includes(`/${name}-templates`));
      if (isExpanded) {
        expandedMenus.push(name);
      }
    });
    setExpandedPanels(expandedMenus);
  }, [location, menuType, menuNames]);

  const handlePanelChange = (panelName: string) => {
    if (expandedPanels.includes(panelName)) {
      const updatedPanels = expandedPanels.filter((name) => name !== panelName);
      setExpandedPanels(updatedPanels);
    } else {
      setExpandedPanels([...expandedPanels, panelName]);
    }
  };

  function visibleGrants(menuName: string) {
    let filterAwardType: PlanType | null = null;
    if (menuName.startsWith('cpg')) {
      filterAwardType = 'CPG';
    } else if (menuName.startsWith('wp')) {
      filterAwardType = 'WORK_PLAN';
    } else if (menuName.startsWith('sp')) {
      filterAwardType = 'SPEND_PLAN';
    } else {
      return [];
    }

    return grants.filter(
      (g) =>
        g.modules.includes(filterAwardType!) &&
        roles.some(
          (r) =>
            r.assignedGrants.includes(g.awardType) &&
            r.assignedPlans.includes(filterAwardType)
        )
    );
  }

  return (
    <>
      {menuNames.map((name) => (
        <Accordion
          key={name}
          allowZeroExpanded={true}
          preExpanded={expandedPanels}
          onChange={() => handlePanelChange(name)}
          className="overflow-x-hidden"
        >
          <AccordionItem dangerouslySetExpanded={expandedPanels.includes(name)}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <MenuItem
                  className="flex w-full"
                  key={`Sidebar menu item for: ${name}`}
                >
                  <span className={`${name}-icon mt-2.5 ml-2.5`} />
                  <div className="my-3 ml-4 grow">
                    {menuOptions[name] || ''}
                  </div>
                  <div className="ml-2 pt-2.5">
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="dropdown-icon--closed mt-1.5" />
                        ) : (
                          <div className="dropdown-icon--open mt-1.5" />
                        )
                      }
                    </AccordionItemState>
                  </div>
                </MenuItem>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {visibleGrants(name).map(
                ({
                  id,
                  grantProgramName,
                }: {
                  id: string;
                  grantProgramName: string;
                }) => {
                  const url =
                    menuType === 'templates'
                      ? `/award-programs/${id}/${name}-templates`
                      : `/award-programs/${id}/${name}`;

                  return (
                    <MenuItem
                      key={id}
                      active={location.pathname.includes(url)}
                      component={<Link to={url} />}
                    >
                      <div className="ps-accordion-item mb-3 pt-3">
                        {grantProgramName}
                      </div>
                    </MenuItem>
                  );
                }
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
};

export default SidebarAccordion;
